import React from "react";
import PromoList from "@/components/PromoList";

const PromoList1 = () => {
  return (
    <div>
      <PromoList cardNumer={1}/>
    </div>
  );
};

export default PromoList1;
